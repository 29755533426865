import React from 'react'
import { RouteObject } from 'react-router-dom'

const DownLoad = React.lazy(() => import('../views/DownLoad'))
const Index2 = React.lazy(() => import('../views/Index2'))
const Home = React.lazy(() => import('../views/Home'))
const Message = React.lazy(() => import('../views/Message'))
const Login = React.lazy(() => import('../views/Login'))
const Lead = React.lazy(() => import('../views/Lead'))
const Installed = React.lazy(() => import('../views/Installed'))

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <DownLoad />
  },
  {
    path: '/remote',
    element: <Index2 />
  },
  {
    path: '/home',
    element: <Home />
  },

  {
    path: '/message',
    element: <Message />
  },
  {
    path: '/login',
    element: <Login />
  },

  // 引导页面
  {
    path: '/lead',
    element: <Lead />
  },

  {
    path: '/installed',
    element: <Installed />
  }
]
