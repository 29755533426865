import { routes } from './routes'
import { useNavigate, useRoutes } from 'react-router-dom'
import './App.css'
import { SpinLoading } from 'antd-mobile'
import { Suspense, useEffect } from 'react'
import { UAParser } from 'ua-parser-js'

function App() {
  const params = new URLSearchParams(window.location.search)
  const route = useRoutes(routes)
  const navigate = useNavigate()

  useEffect(() => {
    // 非谷歌浏览器 直接转到引导页面
    const parser = new UAParser(navigator.userAgent)
    const browserName = parser.getBrowser().name
    if (!/Chrome/i.test(browserName!)) {
      // 不是谷歌则跳转到引导页面
      setTimeout(() => {
        console.log(
          'App',
          browserName,
          params.toString(),
          params.toString().length
        )

        navigate(`/lead?${params.toString()}`, { replace: true })
      }, 50)
    }
  }, [])

  return (
    <div className="App">
      <Suspense fallback={<SpinLoading color="primary"></SpinLoading>}>
        {route}
      </Suspense>
    </div>
  )
}

export default App
